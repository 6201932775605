import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  arrowBackOutline,
  phonePortraitOutline,
  person,
  mail,
  informationCircle,
  camera,
  home,
  logoFacebook
} from "ionicons/icons";
// import useAuth from "../../../../hooks/useAuth";
import { fetchCategories } from "../../../../hooks/redux/actions/categoriesAction";
import { RootStore } from "../../../../hooks/redux/store";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { usePhotoGallery } from "../../../../hooks/usePhotoGallery";
import AtModalMaps from "../../../common/AtModalMaps";
import axios from "axios";

const ModalAccountDetails: React.FC<any> = ({
  isOpen,
  onClose,
  data,
  updateData,
}) => {
  const [result, setResult] = useState<any>([]);
  const [isOpenMaps, setIsOpenMaps] = useState<boolean>(false);
  const { photos, takePhoto, deletePhotos, uploadPhoto } = usePhotoGallery();

  const dispatch: any = useDispatch();
  const allCategories = useSelector((state: RootStore) => state.allCategories);
  const [stateData, setStateData] = useState<any>(data);
  const [newCategories, setNewCategories] = useState([]);
  const getPlaceName = (coords: any) => {
    const lat = coords[0],
      lon = coords[1];
    const baseURL = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=jsonv2&addressdetails=1`;
    axios.get(baseURL).then((res: any) => {
      setStateData({ ...stateData, location: res.data.display_name });
    });
  };

  useEffect(() => {
    if (isOpen) {
      window.localStorage.setItem("isProfileShown", "true");
      let { categories, longitude, latitude } = data;
      setNewCategories(categories?.replaceAll(", ", ",").split(","));

      setStateData({ ...data, categories });
      getPlaceName([latitude, longitude]);
      dispatch(fetchCategories());
      deletePhotos();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (result) { 
      let addressData: any = { ...result?.address, municipality: "", city: "" };
 
      if (!addressData?.state && addressData?.state_district) {
        addressData = { ...addressData, state: addressData?.state_district };
      }
      if (!addressData?.municipality && addressData?.quarter) {
        addressData = { ...addressData, municipality: addressData?.quarter };
      }
      if (!addressData?.city && addressData?.town) {
        addressData = { ...addressData, city: addressData?.town };
      }
      if (
        !addressData?.neighbourhood &&
        (addressData?.residential || addressData?.amenity)
      ) {
        addressData = {
          ...addressData,
          neighbourhood: addressData?.residential || addressData?.amenity,
        };
      }
      const { name: location, lat: latitude, lon: longitude } = result;
      setStateData({
        ...stateData,
        ...addressData,
        location,
        latitude,
        longitude,
      });
    }
    // eslint-disable-next-line
  }, [result]);

  const savePhoto = async (photo: any) => {
    const files: any = await uploadPhoto(photo);
    // console.log("filename>>>>", files[0]?.filename);
    setStateData({ ...stateData, photo: files[0]?.path });
    // setStateData({ ...stateData, photo: files[0]?.filename });
  };

  useEffect(() => {
    if (photos?.length > 0) {
      savePhoto(photos[0]);
    }
    // eslint-disable-next-line
  }, [photos]);

  const onDismiss = () => { 
    deletePhotos();
    onClose(1);
  };

  const saveInfo = () => {
    updateData({ ...stateData, categories: newCategories?.join(",") });
    onClose(1);
  };
  const onClick_Photo = () => {
    takePhoto("user_profile_");
    // takePhoto();
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>My Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding pad-60-bottom">
        <IonCard color="none">
          <IonCardContent>
            <IonItem
              className="at-photo at-pad-20-bottom"
              color="none"
              lines="none"
            >
              <div className="at-photo-container">
                <img
                  alt="iPaayos"
                  className="task-image-circle"
                  // slot="start"
                  src={
                    stateData?.photo
                      ? stateData?.photo
                      : "/assets/images/person-orange.png"
                  }
                  width="120"
                  height="120"
                />
                <IonIcon
                  className="camera cursor-pointer"
                  icon={camera}
                  color="tertiary"
                  onClick={onClick_Photo}
                ></IonIcon>
              </div>
            </IonItem>
 
            <IonItem color="none" className="at-phone-number">
              <IonLabel
                position="floating"
                aria-label={`${stateData?.mobile_number + ""}`}
              >
                Mobile Number
              </IonLabel>
              <IonIcon
                color="primary"
                icon={phonePortraitOutline}
                size="small"
                slot="start"
              />
              <PhoneInput
                defaultCountry="PH"
                country="PH"
                // placeholder="Enter phone number"
                value={stateData?.mobile_number}
                // onChange={setValue}
                onChange={(value: any) =>
                  setStateData({ ...stateData, mobile_number: value! })
                }
                rules={{ required: true }}
              />
              <IonInput
                value={stateData?.mobile_number}
                // onFocus={(e) => setStateData({ ...stateData, mobile_number: "" })}
                onIonChange={(e) =>
                  setStateData({ ...stateData, mobile_number: e.detail.value! })
                }
                style={{ display: "none" }}
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Full Name</IonLabel>
              <IonIcon
                color="primary"
                icon={person}
                size="small"
                slot="start"
              />
              <IonInput
                value={stateData?.name}
                // onFocus={(e) => setStateData({ ...stateData, name: "" })}
                onIonChange={(e) =>
                  setStateData({ ...stateData, name: e.detail.value! })
                }
              />
            </IonItem>{" "}
            <IonItem color="none">
              <IonLabel position="floating">Email Address</IonLabel>
              <IonIcon color="primary" icon={mail} size="small" slot="start" />
              <IonInput
                value={stateData?.email}
                // onFocus={(e) => setStateData({ ...stateData, email: "" })}
                onIonChange={(e) =>
                  setStateData({ ...stateData, email: e.detail.value! })
                }
                readonly={true}
                disabled={true}
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Address</IonLabel>
              <IonIcon
                color="primary"
                icon={home}
                size="small"
                slot="start"
              />
              <IonTextarea
                value={stateData?.address}
                onIonChange={(e) =>
                  setStateData({ ...stateData, address: e.detail.value! })
                }
                rows={3}
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Facebook</IonLabel>
              <IonIcon
                color="primary"
                icon={logoFacebook}
                size="small"
                slot="start"
              />
              <IonInput
                value={stateData?.facebook}
                onIonChange={(e) =>
                  setStateData({ ...stateData, address: e.detail.value! })
                } 
              />
            </IonItem>
            <IonItem color="none" className="at-pad-10-right">
              <IonLabel slot="start" style={{ maxWidth: "80px" }}>
                Location
              </IonLabel>
              <IonText>{stateData?.location}</IonText>
              <IonButton
                slot="end"
                className="at-pad-10"
                onClick={() => setIsOpenMaps(true)}
              >
                Set Location Map
              </IonButton>
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">About</IonLabel>
              <IonIcon
                color="primary"
                icon={informationCircle}
                size="small"
                slot="start"
              />
              <IonTextarea
                value={stateData?.about}
                // onFocus={(e) => setStateData({ ...stateData, about: "" })}
                onIonChange={(e) =>
                  setStateData({ ...stateData, about: e.detail.value! })
                }
                rows={3}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>My Services</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
          <IonItem color="none">
              <IonLabel
                position="floating"
                className={`${
                  stateData?.categories?.length > 0 ? "ion-select-label" : ""
                }`}
              >
                Select job category
              </IonLabel>
              <IonSelect
                multiple={true}
                value={newCategories}
                onIonChange={(e) => setNewCategories(e.detail.value!)}
              >
                {allCategories?.length > 0 &&
                  allCategories?.map((option: any, i: number) => (
                    <IonSelectOption value={option?.name} key={i}>
                      {option?.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
 
          </IonCardContent>
        </IonCard>
        {/* <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>Document Verification</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
             
            <IonItem color="none">
              <IonLabel position="floating">Select job category</IonLabel>
              <IonInput
                value={state.category}
                onFocus={(e) => setStateData({ ...state, category: "" })}
                onIonChange={(e) =>
                  setStateData({ ...state, category: e.detail.value! })
                }
              />
            </IonItem>
          </IonCardContent>
        </IonCard> */}
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Update Info
        </IonButton>
      </div>
      <AtModalMaps
        isOpen={isOpenMaps}
        onClose={() => {
          setIsOpenMaps(false);
        }}
        setResult={setResult}
        latLong={[data?.latitude, data?.longitude]}
      />
      {/* <>{console.log("stateData>>>>>>", stateData)}</> */}
      <>{console.log("newCategories>>>>>>", newCategories)}</>
    </IonModal>
  );
};

export default ModalAccountDetails;
