import { IonCard, IonCardContent, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import AtListItem from "../../../common/AtListItem";

const TaskItems: React.FC<any> = ({data, onClickItem}) => {
  return (
    <>
      <IonCard color="none" at-default>
        <IonCardContent>
          {data?.length <= 0 ? (
            <IonItem color="none" lines="none">
              <IonLabel className="at-center at-margin-50-top">
                No task available in your area yet.
              </IonLabel>
            </IonItem>
          ) : (
            <AtListItem
              data={data}
              isMergedLine2={false}
              onClickItem={onClickItem}
              isTextWrap={true}
            />
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default TaskItems;
