import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import React from "react";
import { arrowBackOutline } from "ionicons/icons";
const ModalSubscription: React.FC<any> = ({
  isOpen,
  onClose,
  // setResult
}) => {
  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle className="at-center"> My Subscription</IonTitle>
          <IonButtons slot="end">
            <IonButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" at-default>
        <IonCard color="none">
          <IonCardHeader className="at-center">
            <IonCardTitle style={{ fontSize: "48px", fontWeight: "bold" }}>
              05
            </IonCardTitle>
            <IonCardSubtitle>
              Customer leads remaining today <br /> 25 days left
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
        <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>Purchase Subscription</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonItem className="at-border-radius cursor-pointer" onClick={()=>alert("Unli")}>
              <IonLabel className="pad-20-left at-size-20" slot="start">
              <div className="color-primary"> Unli Plan</div> <h3> Get unlimited leads for a month</h3>
              </IonLabel>
              <IonLabel className="pad-20-right at-size-20" slot="end">
              <div className="color-primary"> <small>PHP</small> 1,000.00</div>  <h3>&nbsp;</h3>
              </IonLabel>
            </IonItem>
            <IonItem className="at-border-radius margin-10-tb cursor-pointer"onClick={()=>alert("Eco")}>
              <IonLabel className="pad-20-left at-size-20" slot="start">
              <div className="color-primary">Eco Plan</div> <h3> Get 5 leads everyday for a month</h3>
              </IonLabel>
              <IonLabel className="pad-20-right at-size-20" slot="end">
              <div className="color-primary"><small>PHP</small> 500.00</div> <h3>&nbsp;</h3>
              </IonLabel>
            </IonItem>
            <IonItem className="at-border-radius margin-10-tb cursor-pointer"onClick={()=>alert("Basic")}>
              <IonLabel className="pad-20-left at-size-20 " slot="start">
                <div className="color-primary">Basic Plan</div> 
                <h3> Get 1 lead everyday for a month</h3>
              </IonLabel>
              <IonLabel
                className="pad-20-right at-size-20 color-primary"
                slot="end"
              >
                <div className="color-primary"><small>PHP</small> 250.00</div> <h3>&nbsp;</h3>
              </IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default ModalSubscription;
