import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
  IonTextarea,
  useIonToast,
  IonInput,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, closeCircle } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { updateNotification } from "../../../hooks/redux/actions/notificationsAction";
import { updateTaskOffer } from "../../../hooks/redux/actions/taskOffersAction";
import FileUpload from "./messages/FileUpload";
import { downloadFile, getFileExtension, getFilename } from "../../../utils/helpers";

const ModalQuotation: React.FC<any> = ({
  isOpen,
  onClose,
  data,
  sender = "customer",
  isReadOnly = false,
}) => {
  const dispatch: any = useDispatch();
  // const { platform } = useContext(PlatformContext);
  const [present] = useIonToast();
  const [stateData, setStateData] = useState<any>(data);
  const [closeParent, setCloseParent] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any>([]);
 

  useEffect(() => {
    setStateData({ ...data });
    if (data?.documents) {
      const attached = data?.documents?.split(",");
      setAttachments(attached);
    }

    // eslint-disable-next-line
  }, [data]);

  const onDismiss = () => {
    onClose(closeParent ? "close_parent" : "");
  };
  const sendMessage = () => {
    dispatch(updateTaskOffer(stateData));
    const { sender_name, user_id, provider_id } = data;
    dispatch(
      updateNotification({
        user_id,
        provider_id,
        message: `${sender_name} sent a proposal`,
        recipient: process.env.REACT_APP_CHAT_RECIPIENT,
        url_param: "/messages",
      })
    );

    // if (!state?.isSaveMessage) {
    //   setState({ ...state, message: "" });
    // }
    present({
      duration: 1000,
      message: `Proposal sent to ${stateData?.name}.`,
      onDidDismiss: () => {
        setCloseParent(true);
        onDismiss();
      },
      onWillDismiss: () => console.log("will dismiss"),
    });
  };

  const saveInfo = (data: any) => {
    const { message: fileURL } = data;
    const attached = [...attachments, fileURL];
    setAttachments(attached);

    const documents = attached?.join(",");
    setStateData({ ...stateData, documents });

    // alert("saveInfo >>>" + JSON.stringify(data));
  };

 
  const onClick_File = (url: any, fileName: any) => {
    if (isReadOnly) {
      downloadFile(url, fileName);
    }
  };
  const deleteFile = (name: any) => {
    const attached = attachments.filter((n: any) => n !== name);
    setAttachments(attached);
  };
  const showFiles = (name: string, key: number) => {
    const fileName: any = getFilename(name);
    const ext: string = getFileExtension(fileName)?.substr(0, 3);
    return (
      <div
        className="at-center at-attachment"
        title={fileName}
        key={key}
        onClick={() => onClick_File(name, fileName)}
      >
        <img
          alt="iPaayos"
          src={`../assets/images/icon-${ext}-file.png`}
          height="56"
        />
        {!isReadOnly && (
          <IonIcon
            icon={closeCircle}
            onClick={() => deleteFile(name)}
            className="delete at-cursor-pointer"
            color="danger"
            title="Delete file"
          />
        )}
        <div className="at-size-12">{`${fileName?.substr(0, 6)}...${ext}`}</div>
      </div>
    );
  };
  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
        <IonHeader color="none">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="at-center">Proposal</IonTitle>
            <IonButtons slot="end">
              <IonButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" at-default-modal>
          <IonCard color="none" at-default>
            <IonCardContent>
              <IonList>
                <div className="xat-item-list">
                  {/* <img
                    alt="provider"
                    className="task-image"
                    src={`/assets/images/${data?.photo}`}
                    width="60"
                  ></img> */}
                  <IonItem lines="none">
                    <div className="at-pad-10-top">
                      <img
                        alt="iPaayos"
                        className="at-item-photo task-image-circle"
                        src={
                          stateData?.photo
                            ? stateData?.photo?.includes("http")
                              ? stateData?.photo
                              : `/assets/images/${stateData?.photo}`
                            : "/assets/images/ipaayos-logo.jpg"
                        }
                        width="60"
                        height="60"
                      />
                    </div>
                    <div className="at-pad-10-left">
                      {stateData?.name && (
                        <IonCardTitle className="at-size-16">
                          <span>To: {stateData?.name}</span>
                        </IonCardTitle>
                      )}
                      {stateData?.category && (
                        <IonCardSubtitle className="at-size-12 at-margin-5-top">
                          <span className="at-text-wrap column">
                            {stateData?.category}
                          </span>
                        </IonCardSubtitle>
                      )}
                    </div>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel position="stacked" slot="start"></IonLabel>
                    <IonCardSubtitle className="at-margin-20-top">
                      <span>Message</span>
                    </IonCardSubtitle>
                    <IonTextarea
                      readonly={isReadOnly}
                      value={stateData?.remarks}
                      className="at-pad-10"
                      rows={10}
                      style={{ border: "1px solid #999", borderRadius: "5px" }}
                      onIonChange={(e) =>
                        setStateData({ ...stateData, remarks: e.detail.value! })
                      }
                    />
                    <span className="at-margin-10-top"></span>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Estimated Cost ( ₱ )</IonLabel>
                    <IonInput
                      readonly={isReadOnly}
                      value={stateData?.estimated_cost}
                      type="number"
                      onIonChange={(e) =>
                        setStateData({
                          ...stateData,
                          estimated_cost: e.detail.value!,
                        })
                      }
                      className="at-right"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Estimated Day(s)</IonLabel>
                    <IonInput
                      readonly={isReadOnly}
                      value={stateData?.estimated_time}
                      type="number"
                      onIonChange={(e) =>
                        setStateData({
                          ...stateData,
                          estimated_time: e.detail.value!,
                        })
                      }
                      className="at-right"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel slot="start">Attachments</IonLabel>
                    <IonText>
                      {attachments?.length > 0 &&
                        attachments?.map((name: any, index: number) => {
                          return showFiles(name, index);
                        })}
                    </IonText>
                    {!isReadOnly && (
                      <FileUpload
                        // info={dataAttach}
                        // room={room}
                        room={`pro_${stateData?.provider_id}`}
                        sender={sender}
                        saveInfo={saveInfo}
                        label="ATTACH"
                      />
                    )}
                  </IonItem>
                </div>
              </IonList>

              {/* <IonItem>
                currency:'PHP', estimated_cost:2500.00, estimated_time:2, //--in
                days remarks:'Additional cost for materials',
                documents:'doc1.pdf,doc2.pdf,doc3.pdf,doc4.pdf',
              </IonItem> */}
            </IonCardContent>
          </IonCard>
        </IonContent>

        <div className="at-menu-bottom">
          {isReadOnly ? (
            <IonButton expand="block" onClick={onDismiss}>
              Close
            </IonButton>
          ) : (
            <>
              <IonButton expand="block" onClick={onDismiss}>
                Cancel
              </IonButton>

              <IonButton expand="block" onClick={sendMessage}>
                {/* <IonIcon icon={thumbsUp} className="at-size-14 at-pad-10-right" />{" "} */}
                &nbsp;Send
              </IonButton>
            </>
          )}
        </div>
      </IonModal>
      {/* <>{console.log("ModalQuotation>>>", state?.isSaveMessage)}</> */}
      <>{console.log("attachments>>>", attachments)}</>
      {/* <>{console.log("stateData>>>", stateData)}</> */}
    </>
  );
};

export default ModalQuotation;
