import React, { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import AtSearchBar from "../common/AtSearchBar";
// import { GetStarRating } from "./modals/appointments/bidding/GetStarRating";
import ModalTasksDetails from "./modals/tasks/ModalTasksDetails";
import AtIonRefresher from "../common/AtIonRefresher";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../hooks/redux/store";
import { fetchTasks } from "../../hooks/redux/actions/tasksAction";
import { formatDateTime } from "../../utils/helpers";
import TaskItems from "./modals/tasks/TaskItems";

const TabTasks: React.FC<any> = () => {
  const dispatch: any = useDispatch();
  const allTasks = useSelector((state: RootStore) => state.allTasks);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<any>();

  const [data, setData] = useState<any>([]);
  const refreshData = async () => {
    await dispatch(
      fetchTasks({
        with_offers: false,
        provider_id: window.localStorage.getItem("id"),
      })
    );

    // dispatch(fetchTasks());
  };

  useEffect(() => {
    // alert("TabTask");
    refreshData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => { 
    // filterData();
    if (allTasks?.length > 0) {
      // console.log("allTasks >>>", allTasks);

      const fields = ["name", "location", "category", "remarks"];
      //-- https://betterprogramming.pub/creating-a-multi-filter-function-to-filter-out-multiple-attributes-javascript-react-rails-5aad8e272142
      let newData = allTasks
        ?.filter((p: any) => {
          return searchText?.length
            ? //--- for OR condition
              // ? searchText?.trim().split(" ")?.some((text: any) => {
              //--- for AND condition
              searchText
                ?.trim()
                .split(" ")
                ?.every((text: any) => {
                  return fields?.some((field: any) =>
                    p[field]?.toLowerCase().includes(text)
                  );
                }) ||
                searchText
                  ?.trim()
                  .split(" ")
                  ?.every((text: any) => {
                    return fields?.some(
                      (field: any) =>
                        p?.user?.length &&
                        p?.user[field]?.toLowerCase().includes(text)
                    );
                  })
            : true;
        })
        ?.map((task: any, index: number) => {
          const {
            id,
            user_id,
            remarks: r4_c1,
            category,
            images,
            tasks_offers,
          } = task;

          let user_tmp = {
            name: null,
            photo: null,
            municipality: null,
            city: null,
            region: null,
          };

          let {
            // name: title,
            name,
            photo,
            // road,neighbourhood,country,
            municipality,
            city,
            region,
          } = task?.user || user_tmp;

          const address = [
            // road, neighbourhood,
            municipality,
            city,
            region,
            // country,
          ]
            .filter((x) => x?.length)
            .join(", ");

          const r1_c2 = formatDateTime(task?.ts, "dd MMM");
          const r2_c2 = formatDateTime(task?.ts, "hh:mm aaaaa'm'");

          const provider_id = parseInt("0" + window.localStorage.getItem("id"));
          const task_offer = tasks_offers?.filter(
            (offer: any) => offer?.provider_id === provider_id
          );
          const r2_c1: any = (
            <div>
              {task_offer?.length > 0 && (
                <span className="color-success">PROPOSAL SENT for </span>
              )}
              {category}
            </div>
          );

          let offer = {
            currency: "",
            estimated_cost: 0,
            estimated_time: 0,
            remarks: "",
            documents: "",
          };
          if (task_offer?.length > 0) {
            offer = task_offer[0];
          }
          // let {
          //   currency = "",
          //   estimated_cost = 0,
          //   estimated_time = 0,
          //   remarks = "",
          //   documents = "",
          // } = offer;

          return {
            id,
            user_id,
            provider_id,
            photo,
            images,
            r1_c1: name,
            r1_c2,
            r2_c1,
            r2_c2,
            r4_c1,

            task_id: id,
            category,
            task_offer,
            address,
            // currency: "PHP",
            // estimated_cost: 2500.0,
            // estimated_time: 2, //--in days
            // remarks: "Additional cost for materials",
            // documents: "doc1.pdf,doc2.pdf,doc3.pdf,doc4.pdf",
            // currency,
            // estimated_cost,
            // estimated_time,
            // remarks,
            // documents,
            ...offer,
            // images:'1652858800322.jpg,1652858828620.jpg,165286053327.jpg', //-- from the provider
            name,
            latlong: "",
            sender_name: window.localStorage.getItem("name"),
          };
        });

      setData(newData);
    }
    // eslint-disable-next-line
  }, [allTasks, searchText]);

  const onClickItem = (id: number) => {
    setIsModalOpen(true);
    // alert("onClickItem id: " + id);
    const dat = data.find((dat: any) => dat.id === id);
    console.log("dat >>>>", dat);
    setCurrentItem(dat);
  };

  return (
    <>
      {currentItem && (
        <ModalTasksDetails
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          data={currentItem}
          isReadOnly={true}
        />
      )}

      <IonContent className="ion-padding " scrollEvents={true}>
        <AtSearchBar setSearchText={setSearchText} noFocus={true} />
        {allTasks?.length > 0 && (
          <TaskItems data={data} onClickItem={onClickItem} />
        )}
        <AtIonRefresher refreshData={refreshData} />
      </IonContent>
      {/* {console.log("allTasks>>>", allTasks)} */}
      {/* {console.log("data>>>", data)} */}
      {/* {console.log("searchText>>>", searchText.split(" "))} */}
    </>
  );
};

export default TabTasks;
