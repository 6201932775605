import ModalAccountDetails from "./account/ModalAccountDetails";
import ModalFAQs from "./account/ModalFAQs";
import ModalSubscription from "./account/ModalSubscription";
import ModalSupport from "./account/ModalSupport";
import ModalTerms from "./account/ModalTerms";
import ModalAppointmentsStatus from "./appointments/ModalAppointmentsStatus";
/*****
 Components name (in lower case) must be equal to MODALS name (in upper case)
 eg. job_status === JOB_STATUS
*/
export const Components: any = {
  account: ModalAccountDetails,
  subscription: ModalSubscription,
  support: ModalSupport,
  terms: ModalTerms,
  faqs: ModalFAQs,
  task_status: ModalAppointmentsStatus,
};
export enum MODALS {
  ACCOUNT,
  SUBSCRIPTION,
  SUPPORT,
  TERMS,
  FAQS,
  TASK_STATUS,
}
