import { FETCH_PROVIDER, UPDATE_PROVIDER } from "../actions/types";

const ProviderReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case UPDATE_PROVIDER:
      return action.payload;
    case FETCH_PROVIDER:
      if (action.payload?.provider) {
        return action.payload?.provider;
      } else {
        return {};
      }
    // return action.payload?.provider;
    default:
      return state;
  }
};
export default ProviderReducer;
