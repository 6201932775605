import { USER_SIGNIN, USER_SIGNUP, USER_UPDATE, VERIFY_OTP } from "./types";
import axiosInstance from "../../../lib/axios";

export const userSignIn = (userData: any) => async (dispatch: any) => {
  try {
    console.log("userSignIn userData>>>>>", userData)
    const { data } = await axiosInstance.post("provider/signin", userData);

    console.log("userSignIn data>>>>>", data)
    // const data = {
    //   email: "avtividad@yahoo.com ",
    //   firstname: "adonis",
    //   lastname: "tividad",
    //   is_logged:true,

    // };

    dispatch({
      type: USER_SIGNIN,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: USER_SIGNIN,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching data.",
        items: [],
      },
    });
  }
};

export const userSignUp = (userData: any) => async (dispatch: any) => {
  try {
    console.log("userData >>>", userData)
    const { data } = await axiosInstance.post("provider/signup", userData);
    console.log("data >>>", data)

    // const data = {
    //   email: "avtividad@yahoo.com ",
    //   firstname: "adonis",
    //   lastname: "tividad",
    //   is_logged:true,

    // };

    dispatch({
      type: USER_SIGNUP,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: USER_SIGNUP,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching data.",
        items: [],
      },
    });
  }
};

export const updateUser = (userData: any) => async (dispatch: any) => {
  try {
    console.log("userData >>>", userData)
    const { data } = await axiosInstance.post("provider/update", userData);
    console.log("data >>>", data)
 
    dispatch({
      type: USER_UPDATE,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: USER_UPDATE,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching data.",
        items: [],
      },
    });
  }
};
export const verifyOTP = (userData: any) => async (dispatch: any) => {
  try {
    // console.log("userData >>>", userData)
    const { data } = await axiosInstance.post("provider/verify-otp", userData);
    // console.log("data >>>", data)
 
    dispatch({
      type: VERIFY_OTP,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching data.",
        items: [],
      },
    });
  }
};
export const resendOTP = (userData: any) => async (dispatch: any) => {
  try {
    console.log("userData >>>", userData)
    const { data } = await axiosInstance.post("provider/send-otp", userData);
    console.log("data >>>", data)
 
    dispatch({
      type: VERIFY_OTP,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching data.",
        items: [],
      },
    });
  }
};

/***********
// export const fetchUserDetails = (userData: any) => async (dispatch: any) => {
export const fetchUser = (id: number) => async (dispatch: any) => {
  try {
    // console.log("userData>>>", JSON.stringify(userData));
    const { data } = await axiosInstance.post("user/fetch-user-details", {
      id,
    });

    // console.log("fetchUserDetails data>>>", data.details);
    dispatch({
      // type: FETCH_USER_DETAILS,
      type: FETCH_USER,
      payload: data,
    });
    dispatch({
      type: FETCH_USER_PROFILE,
      payload: data,
    });
    dispatch({
      type: FETCH_PROVIDER_PROFILE,
      payload: data,
    });
  } catch (e) {
    dispatch({
      // type: FETCH_USER_DETAILS,
      type: FETCH_USER,
      payload: {
        status: 500,
        msg: "Something went wrong while fetching user details.",
        items: [],
      },
    });
  }
};

export const updateUserDetails = (userData: any) => async (dispatch: any) => {
  try {
    console.log("userData>>>", JSON.stringify(userData));
    const result = await axiosInstance.post(
      "user/update-user-details",
      userData
    );

    console.log("result>>>", result);

    dispatch({
      type: UPDATE_USER_DETAILS,
      payload: userData,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_USER_DETAILS,
      payload: {
        status: 500,
        msg: "Something went wrong while updating user details.",
        items: [],
      },
    });
  }
};
***********/