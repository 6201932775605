import { combineReducers } from "redux";
import BadgesReducer from "./BadgesReducer";

import CategoriesReducer from "./CategoriesReducer";
import GroupedMessagesReducer from "./GroupedMessagesReducer";
import MessagesReducer from "./MessagesReducer";
import NotificationsReducer from "./NotificationsReducer";
import ProviderReducer from "./ProviderReducer"; 
import TaskAppointmentsReducer from "./TaskAppointmentsReducer";
import TaskOffersReducer from "./TaskOffersReducer";
import TasksReducer from "./TasksReducer";
import UserReducer from "./UserReducer";  

const rootReducer = combineReducers({
  allCategories: CategoriesReducer,
  oneProvider: ProviderReducer,
  user:UserReducer,
  allTasks: TasksReducer,
  allTaskAppointments: TaskAppointmentsReducer,
  allTaskOffers: TaskOffersReducer,
  allGroupedMessages: GroupedMessagesReducer,
  allMessages: MessagesReducer,
  allNotifications: NotificationsReducer,
  allBadges: BadgesReducer,
  // allProviders: ProvidersReducer,
  // myList: MyListReducer,
  // user: UserReducer,
  // user_profile: UserProfileReducer,
  // provider_profile: ProviderProfileReducer,
  // provider: ProvidersReducer,
  // allTaskOffers: AllTaskOffersReducer,
  // taskOffers: TaskOffersReducer,
  // allReviews: ReviewsReducer,
});

export default rootReducer;
