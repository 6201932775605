import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
  IonCard,
  IonCardContent,
  IonImg,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import ModalQuotation from "../ModalQuotation";
import ImageGallery from "../messages/ImageGallery";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  fetchTaskAppointments,
  fetchTasks,
} from "../../../../hooks/redux/actions/tasksAction";
// import { useDispatch } from "react-redux";
// import { updateTaskOffer } from "../../../../hooks/redux/actions/taskOffersAction";

const ModalTasksDetails: React.FC<any> = ({
  isOpen,
  onClose,
  data,
  // isReadOnly = false,
}) => {
  const dispatch: any = useDispatch();
  const [isOpenSendMessage, setIsOpenSendMessage] = useState<boolean>(false);
  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [stateData, setStateData] = useState<any>([]);
  const [imageList, setImageList] = useState<any>([]);
  const [slideId, setSlideId] = useState<number>(0);
  const [fallback, setFallback] = useState(false);
  const {
    id,
    photo,
    r1_c1,
    r1_c2,
    r2_c1,
    r2_c2,
    r4_c1,
    name,
    address,
    images,
    task_offer_id = 0,
    task_offer,
  } = data;
  const [offerSent, setOfferSent] = useState<boolean>(task_offer?.length > 0);
  const history = useHistory();

  // const dispatch: any = useDispatch();

  const onDismiss = () => {
    onClose(1);
  };

  useEffect(() => {
    if (data) {
      let imgList: any = [];
      images
        ?.split(",")
        ?.map((img: any, index: number) =>
          imgList.push({ id: index, photo: img })
        );
      // console.log("imgList >>>>", imgList);
      setOfferSent(task_offer?.length > 0);
      setImageList(imgList);
      setStateData({ ...data, id: task_offer_id });
    }
    // eslint-disable-next-line
  }, [data]);

  const showImageGallery = () => {
    return (
      <ImageGallery
        isOpen={isOpenViewer}
        onClose={() => setIsOpenViewer(false)}
        data={imageList}
        slideId={slideId}
        name={name}
      />
    );
  };
  const getImageSrc = () => {
    return photo
      ? photo?.includes("http")
        ? `${photo}?${Date.now()}`
        : `/assets/images/${photo}?${Date.now()}`
      : "/assets/images/ipaayos-logo.jpg";
  };
  const reloadSrc = (e: any) => {
    if (fallback) {
      e.target.src = "/assets/images/ipaayos-logo.jpg";
    } else {
      e.target.src = getImageSrc();
      setFallback(true);
    }
  };

  // const saveInfo = (data: any) => {
  //   /****
  //                currency:'PHP',
  //           estimated_cost:2500.00,
  //           estimated_time:2, //--in days
  //           remarks:'Additional cost for materials',
  //           documents:'doc1.pdf,doc2.pdf,doc3.pdf,doc4.pdf',
  //    */
  //   // console.log("saveInfo data >>>>", data)

  //   dispatch(updateTaskOffer(data));
  //   // onClose(1);
  // };
  return (
    <>
      {isOpen && (
        <ModalQuotation
          isOpen={isOpenSendMessage}
          onClose={async (e: any) => {
            setIsOpenSendMessage(false);
            if (e === "close_parent") {
              onDismiss();
              const provider_id = window.localStorage.getItem("id");
              await dispatch(fetchTasks({ with_offers: false, provider_id })); 
              await dispatch(
                fetchTaskAppointments({ with_offers: true, provider_id })
              );
              history.push("/appointments?m=bidding");
              
            }
          }}
          data={stateData}
          sender="provider"
          isReadOnly={offerSent}
        />
      )}

      <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
        <IonHeader color="none">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="at-center">Task Details</IonTitle>
            <IonButtons slot="end">
              <IonButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" at-default-modal>
          <IonCard color="none" at-default>
            <IonCardContent>
              <IonList>
                <div className="at-list-item" id={id}>
                  <IonImg
                    alt="iPaayos"
                    className="at-item-image at-item-image-circle"
                    src={getImageSrc()}
                    onError={reloadSrc}
                  />
                  <IonItem lines="none">
                    <IonLabel position="stacked" slot="start"></IonLabel>

                    <IonText className="at-item-row">
                      <IonCardTitle className="at-size-16 at-text-wrap">
                        {r1_c1}
                      </IonCardTitle>

                      <IonCardTitle className="at-size-14 at-text-wrap at-right">
                        {r1_c2}
                      </IonCardTitle>
                    </IonText>

                    <div className="at-item-row ">
                      <IonCardSubtitle className="at-size-12 at-text-wrap">
                        {r2_c1}
                      </IonCardSubtitle>
                      <IonCardSubtitle className="at-size-12 at-right">
                        {r2_c2}
                      </IonCardSubtitle>
                    </div>

                    <div>
                      <div className="at-size-14 at-margin-10-top">
                        Location
                      </div>
                      <IonCardSubtitle className="at-size-12">
                        <p>{address}</p>
                      </IonCardSubtitle>
                    </div>
                    <div>
                      <div className="at-size-14 at-margin-10-top">
                        Description
                      </div>
                      <IonCardSubtitle className="at-size-12">
                        <p>{r4_c1}</p>
                      </IonCardSubtitle>
                    </div>

                    <div className="xat-item-row at-pad-20-top">
                      {imageList?.length &&
                        imageList?.map((img: any, index: number) => {
                          return (
                            <span className="at-pad-10-right" key={index}>
                              <img
                                key={index}
                                alt="iPaayos"
                                className="task-image-large"
                                src={
                                  img?.photo
                                    ? img?.photo
                                    : "/assets/images/person-orange.png"
                                }
                                onClick={() => {
                                  setSlideId(index);
                                  setIsOpenViewer(true);
                                }}
                              />{" "}
                            </span>
                          );
                        })}
                    </div>
                  </IonItem>
                </div>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonContent>
        <div className="at-menu-bottom">
          <IonButton expand="block" onClick={() => setIsOpenSendMessage(true)}>
            &nbsp; {offerSent ? "View" : "Send"} Quotation
          </IonButton>
        </div>
      </IonModal>

      {showImageGallery()}
      {/* <>{console.log("photos>>>>>", imageList, images)}</> */}
      {/* <>{console.log("ModalTaskDetails data>>>>>", data)}</> */}
      <>{console.log("ModalTaskDetails stateData>>>>>", stateData)}</>
    </>
  );
};

export default ModalTasksDetails;
//-- Should refresh every 5 minutes if task status is PENDING to check on Booking Confirmation
